import AES from 'crypto-js/aes';
import Utf8 from 'crypto-js/enc-utf8';
import { secretKey } from './config';

export function encryptData(data: string): string {
  return AES.encrypt(data, secretKey).toString();
}

export function decryptData(encryptedData: string): string | undefined {
  try {
    const bytes = AES.decrypt(encryptedData, secretKey);
    const decryptedData = bytes.toString(Utf8);
    if (!decryptedData) {
      throw new Error("Malformed UTF-8 data");
    }
    return decryptedData;
  } catch (error) {
    console.error("Failed to decrypt data:", error);
    return undefined;  // Return undefined when decryption fails
  }
}
