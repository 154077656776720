import React, { useEffect, useState } from "react";
import { db, auth } from "./firebase";
import {
  collection,
  doc,
  setDoc,
  getDoc,
  deleteDoc,
} from "firebase/firestore";
import { encryptData,decryptData } from "./cryptoUtils";

function Scratchpad() {
  const [scratchpadText, setScratchpadText] = useState<string>("");

  useEffect(() => {
    const fetchScratchpad = async () => {
      if (auth.currentUser) {
        const scratchpadRef = doc(db, `users/${auth.currentUser.uid}/notes/scratchpad`);
        const docSnap = await getDoc(scratchpadRef);
        if (docSnap.exists()) {
          setScratchpadText(decryptData(docSnap.data().text) || "");
        }
      }
    };

    fetchScratchpad();
  }, [auth.currentUser]);

  // Debounce the saving function to avoid excessive writes
  const debounceSaveToFirebase = React.useCallback(
    debounce(async (text: string) => {
      if (auth.currentUser) {
        const scratchpadRef = doc(db, `users/${auth.currentUser.uid}/notes/scratchpad`);
        await setDoc(scratchpadRef, { text: encryptData(text) }); // Encrypt before saving
      }
    }, 1000), 
    [auth.currentUser]
  );

  const handleTextChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newText = event.target.value;
    setScratchpadText(newText);
    debounceSaveToFirebase(newText); // Save the text after the debounce delay
  };

  const clearScratchpad = async () => {
    if (auth.currentUser) {
      const scratchpadRef = doc(db, `users/${auth.currentUser.uid}/notes/scratchpad`);
      await deleteDoc(scratchpadRef);
      setScratchpadText("");
    }
  };

  return (
    <div className="my-4">
      <h2 className="text-2xl font-bold mb-2">Scratchpad</h2>
      <textarea
        className="w-full h-44 p-2 border-2 border-gray-300 rounded-md"
        value={scratchpadText}
        onChange={handleTextChange}
        placeholder="Type here to save a quick note to self."
      />
      <button className="text-sm text-red-600 mb-2" onClick={clearScratchpad}>
        Clear Scratchpad
      </button>
    </div>
  );
}

// Debounce utility function
function debounce(func: (...args: any[]) => void, delay: number) {
  let timer: NodeJS.Timeout | null;
  return function (...args: any[]) {
    if (timer) {
      clearTimeout(timer);
    }
    timer = setTimeout(() => func(...args), delay);
  };
}

export default Scratchpad;
