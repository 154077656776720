import React, { useEffect, useState, ChangeEvent } from "react";
import { db, auth } from "./firebase";
import {
  collection,
  addDoc,
  onSnapshot,
  doc,
  updateDoc,
  deleteDoc,
} from "firebase/firestore";
import { encryptData, decryptData } from './cryptoUtils';

import fallbackIcon from "./fallback-icon.png";
import { XCircleIcon } from "@heroicons/react/20/solid";

import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
} from "react-beautiful-dnd";

type UrlData = {
  id: string;
  url: string;
  name?: string;
  order: number;
};

function UrlManager() {
  const [userUrls, setUserUrls] = useState<UrlData[]>([]);
  const [newUrl, setNewUrl] = useState<string>("");
  const [newUrlName, setNewUrlName] = useState<string>("");
  const [showAddForm, setShowAddForm] = useState<boolean>(false);
  const [newlyAdded, setNewlyAdded] = useState<string | null>(null);
  const [hoveredId, setHoveredId] = useState<string | null>(null);

  useEffect(() => {
    const fetchUrls = async () => {
      if (auth.currentUser) {
        const urlsRef = collection(db, `users/${auth.currentUser.uid}/urls`);
        const unsubscribe = onSnapshot(urlsRef, (snapshot) => {
          const docs = snapshot.docs.map((doc) => {
            let url = doc.data().url;
            let name = doc.data().name;

            try {
              url = decryptData(url) ?? url;
            } catch (error) {
              console.error("Failed to decrypt URL:", error);
            }

            try {
              name = name ? decryptData(name) : url;
            } catch (error) {
              console.error("Failed to decrypt name:", error);
              name = url;
            }

            return {
              id: doc.id,
              url,
              name,
              order: doc.data().order,
            };
          }) as UrlData[];

          docs.sort((a, b) => a.order - b.order); // Sort URLs by order
          setUserUrls(docs);
        });

        return () => unsubscribe();
      }
    };
    fetchUrls();
  }, [auth.currentUser]);

  const handleUrlChange = (event: ChangeEvent<HTMLInputElement>) => {
    setNewUrl(event.target.value);
  };

  const handleUrlNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setNewUrlName(event.target.value);
  };

  const addUrl = async (): Promise<void> => {
    if (userUrls.length < 50) {
      let formattedUrl: string = newUrl;

      // Prepend "http://" if no protocol is specified
      if (!/^https?:\/\//i.test(newUrl)) {
        formattedUrl = "https://" + newUrl;
      }

      const urlObject = new URL(formattedUrl);
      const domainName = urlObject.hostname;

      // URL validation
      try {
        new URL(formattedUrl);
      } catch (_) {
        alert("Please enter a valid URL");
        return;
      }

      // Check for duplicates
      if (userUrls.some((urlData: UrlData) => urlData.url === formattedUrl)) {
        alert("You have already added this URL.");
        return;
      }

      const encryptedUrl = encryptData(formattedUrl);
      const encryptedUrlName = newUrlName 
        ? encryptData(newUrlName) 
        : encryptData(domainName);  // If no name provided, use domain name

      const urlsRef = collection(db, `users/${auth.currentUser?.uid}/urls`);
      const docRef = await addDoc(urlsRef, {
        url: encryptedUrl,
        name: encryptedUrlName,
        order: userUrls.length,
      });

      setNewUrl("");
      setNewUrlName("");
      setShowAddForm(false);
      setNewlyAdded(docRef.id);
      setTimeout(() => setNewlyAdded(null), 4000);
    } else {
      alert("Maximum limit of 50 URLs reached");
    }
  };

  const updateUrl = async (id: string, url: string, name: string) => {
    const encryptedUrl = encryptData(url);
    const encryptedUrlName = encryptData(name); 

    const urlRef = doc(db, `users/${auth.currentUser?.uid}/urls/${id}`);
    await updateDoc(urlRef, { url: encryptedUrl, name: encryptedUrlName }); 
  };
  
  const deleteUrl = async (id: string) => {
    if (window.confirm("Are you sure you want to delete this URL?")) {
      const urlRef = doc(db, `users/${auth.currentUser?.uid}/urls/${id}`);
      await deleteDoc(urlRef);
    }
  };

  const handleFaviconError = (e: React.SyntheticEvent<EventTarget>) => {
    const target = e.target as HTMLImageElement;
    target.src = fallbackIcon; // Set the source to your fallback image
    target.onerror = null; // Remove the onerror handler to avoid infinite loops in case the fallback also fails
  };

  const handleOnDragEnd = async (result: DropResult) => {
    if (!result.destination) return;

    const items = Array.from(userUrls);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setUserUrls(items); // This line is for immediate UI update

    // Now, we'll update Firebase
    for (let i = 0; i < items.length; i++) {
      const urlData = items[i];
      const urlRef = doc(
        db,
        `users/${auth.currentUser?.uid}/urls/${urlData.id}`
      );
      await updateDoc(urlRef, { order: i }); // Update the order field of each URL
    }
  };

  return (
    <div className="max-w-7xl mx-auto">
      <div className="my-4">
        <DragDropContext onDragEnd={handleOnDragEnd}>
          <Droppable droppableId="urls" direction="horizontal">
            {(provided, snapshot) => (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
                className="grid sm:grid-cols-4 gap-2 sm:gap-4 mb-6"
                style={{
                  backgroundColor: snapshot.isDraggingOver ? "" : "inherit",
                }}
              >
                {userUrls.map((urlData, index) => (
                  <Draggable key={urlData.id} draggableId={urlData.id} index={index}>
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        className={`relative bg-blue-100 rounded-md p-2 hover:bg-blue-300 shadow-sm truncate ${
                          urlData.id === newlyAdded ? "animate-pulse" : ""
                        }`}
                        onMouseEnter={() => setHoveredId(urlData.id)}
                        onMouseLeave={() => setHoveredId(null)}
                        style={{
                          ...provided.draggableProps.style,
                          boxShadow: snapshot.isDragging ? "0 0 .4rem #666" : "none",
                        }}
                      >
                        <a
                          href={urlData.url}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="block text-ellipsis overflow-hidden"
                        >
                          <img
                            src={urlData.url ? `${new URL(urlData.url).origin}/favicon.ico` : fallbackIcon}
                            onError={handleFaviconError}
                            alt=""
                            width="16"
                            height="16"
                            className="inline mr-2"
                          />
                          {urlData.name || new URL(urlData.url).hostname}
                        </a>
                        <a
                          onClick={() => deleteUrl(urlData.id)}
                          className={`${
                            hoveredId === urlData.id ? "inline-block" : "hidden"
                          } sm:hover:inline-block text-gray-800 absolute right-2 top-3`}
                        >
                          <XCircleIcon className="w-4 h-4" />
                        </a>
                        <a
                          onClick={() => deleteUrl(urlData.id)}
                          className="inline-block sm:hidden text-gray-800 absolute right-2 top-3"
                        >
                          <XCircleIcon className="w-4 h-4 text-gray-800" />
                        </a>
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>

        <button onClick={() => setShowAddForm(!showAddForm)} className="mb-4">
          {showAddForm ? (
            <span className="cancel-text">x</span>
          ) : (
            <span className="bg-pink-200 p-2 rounded-lg w-auto">Add a new link</span>
          )}
        </button>

        {showAddForm && (
          <form
            onSubmit={(e) => {
              e.preventDefault();
              addUrl();
            }}
          >
            <div className="justify-center bg-gray-200 p-4 rounded-md mb-4">
              <label className="text-sm font-medium text-gray-700">
                Link (ie: etrade.com)
              </label>
              <input
                className="flex w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                type="text"
                placeholder=" URL/Link"
                value={newUrl}
                onChange={handleUrlChange}
              />
              <label className="text-sm font-medium text-gray-700">
                Link name (ie: My Stocks)
              </label>
              <input
                className="flex w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                type="text"
                placeholder=" Link name (optional)"
                value={newUrlName}
                onChange={handleUrlNameChange}
              />
              <div className="flex">
                <button
                  type="submit"
                  className="bg-pink-200 text-black p-2 rounded-lg mt-2 w-auto hover:bg-pink-400 hover:text-white"
                >
                  Save link
                </button>
              </div>
            </div>
          </form>
        )}
      </div>
    </div>
  );
}

export default UrlManager;
