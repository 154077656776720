import { SunIcon } from "@heroicons/react/20/solid";
import React, { useState, useEffect, CSSProperties } from "react";
import { auth, db } from "./firebase";
import { doc, setDoc, getDoc } from "firebase/firestore";

const tomorrowStyle: CSSProperties = {
  paddingBottom: "22px",
  position: "relative",
};

const YOUR_API_KEY = "bDSGBaZ5H5P5J3JEAkaSaPf0WX18hXwv"; // Replace with your Tomorrow.io API Key

const TomorrowWidget: React.FC<{ setWidgetRegistered: (value: boolean) => void }> = ({ setWidgetRegistered }) => {
  const [showWidget, setShowWidget] = useState<boolean>(false);
  const [widgetVisible, setWidgetVisible] = useState<boolean>(false);

  useEffect(() => {
    const fetchWidgetStatus = async () => {
      if (auth.currentUser) {
        const userRef = doc(db, `users/${auth.currentUser.uid}`);
        const userDoc = await getDoc(userRef);
        if (userDoc.exists()) {
          const userData = userDoc.data();
          if (userData?.weatherWidgetStatus) {
            setShowWidget(true);
            setWidgetVisible(true);
          } else {
            setShowWidget(false);
            setWidgetVisible(false);
          }
        }
      }
    };

    fetchWidgetStatus();
  }, []);

  const handleAddWeatherWidget = () => {
    setShowWidget(true);
    setWidgetVisible(true);
    setWidgetRegistered(true);
    if (auth.currentUser) {
      const userRef = doc(db, `users/${auth.currentUser.uid}`);
      setDoc(userRef, { weatherWidgetStatus: true }, { merge: true });
    }
  };

  const handleRemoveWeatherWidget = () => {
    setShowWidget(false);
    setWidgetVisible(false);
    setWidgetRegistered(false);
    if (auth.currentUser) {
      const userRef = doc(db, `users/${auth.currentUser.uid}`);
      setDoc(userRef, { weatherWidgetStatus: false }, { merge: true });
    }
  };

  useEffect(() => {
    const handleGeoSuccess = (position: GeolocationPosition) => {
      const { latitude, longitude } = position.coords;
      // Adding Tomorrow.io widget
      (function (d: Document, s: string, id: string) {
        if (d.getElementById(id)) {
          if (window.__TOMORROW__ && window.__TOMORROW__.init) {
            window.__TOMORROW__.init({
              locationId: `${latitude},${longitude}`, // Setting location as per user's location
              language: "EN",
              unitSystem: "IMPERIAL",
              skin: "light",
              widgetType: "upcoming",
              apiKey: YOUR_API_KEY, // Using API Key
            });
          }
          return;
        }
        const fjs = d.getElementsByTagName(s)[0];
        const js = d.createElement(s) as HTMLScriptElement;
        js.id = id;
        js.src = "https://www.tomorrow.io/v1/widget/sdk/sdk.bundle.min.js";
        js.async = true;

        js.onload = () => {
          if (window.__TOMORROW__ && window.__TOMORROW__.init) {
            window.__TOMORROW__.init({
              locationId: `${latitude},${longitude}`, // Setting location as per user's location
              language: "EN",
              unitSystem: "IMPERIAL",
              skin: "light",
              widgetType: "upcoming",
              apiKey: YOUR_API_KEY, // Using API Key
            });
          }
          setWidgetVisible(true); // Show the widget once it's loaded
        };

        if (fjs.parentNode) {
          fjs.parentNode.insertBefore(js, fjs);
        }
      })(document, "script", "tomorrow-sdk");
    };

    const handleGeoError = (error: GeolocationPositionError) => {
      console.log("Error in getting location", error);
    };

    // Requesting user's location
    if (showWidget) {
      navigator.geolocation.getCurrentPosition(handleGeoSuccess, handleGeoError);
    }
  }, [showWidget]);

  return (
    <div style={tomorrowStyle}>
      {widgetVisible && (
        <p
          className="text-sm text-gray-500 relative hover:text-red-500 text-right mb-2 mr-2 cursor-pointer"
          onClick={handleRemoveWeatherWidget}
        >
          Remove
        </p>
      )}
      {showWidget ? (
        <div
          className="tomorrow"
          data-language="EN"
          data-unit-system="IMPERIAL"
          data-skin="light"
          data-widget-type="upcoming"
        />
      ) : (
        <button
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded absolute bottom-0 right-0 mb-2 mr-2"
          onClick={handleAddWeatherWidget}
        >
          <SunIcon className="h-4 w-4 inline-block mr-1" />
          Add Weather Widget
        </button>
      )}
    </div>
  );
};

export default TomorrowWidget;
