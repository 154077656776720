import React, { useEffect, useState } from 'react';
import { auth } from './firebase';
import {
  signInWithRedirect,
  GoogleAuthProvider,
  onAuthStateChanged,
  signOut,
  User,
  getRedirectResult
} from 'firebase/auth';

function Auth() {
  const [user, setUser] = useState<User | null>(null);

  useEffect(() => {
    // Listen to auth state changes
    onAuthStateChanged(auth, setUser);

    // Handle the redirect result after Google sign-in redirect process
    getRedirectResult(auth)
      .then((result) => {
        // If you need to get the user or any tokens, you can do it here.
        // For this example, we're just setting the user.
        if (result && result.user) {
        setUser(result.user);
      }
    })
      .catch((error) => {
        console.error("Error during sign in:", error);
        // Optionally, display a message to the user
        alert("Error during sign in. Please try again.");
      });
  }, []);

  const signInWithGoogle = () => {
    const provider = new GoogleAuthProvider();
    signInWithRedirect(auth, provider);
  };

  const handleSignOut = () => {
    signOut(auth);
  };

  return (
    <div className="flex items-center">
       <div className="flex-shrink-0">
      {user ? (
        <button 
        type="button" 
        className="relative inline-flex items-center gap-x-1.5 rounded-md bg-slate-200 px-3 py-2 text-sm font-normal text-slate-800 shadow-sm hover:bg-rose-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-sky-600"
        onClick={handleSignOut}>Sign out</button>
      ) : (
        <button 
        type="button"
        onClick={signInWithGoogle}
        className="relative inline-flex items-center gap-x-1.5 rounded-md bg-emerald-600 px-3 py-2 text-sm font-normal text-white shadow-sm hover:bg-emerald-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-sky-600">
        Sign in with Google
        </button>
      )}
      </div>
    </div>
  );
}

export default Auth;
