import React, { useState, useEffect } from 'react';
import * as Sentry from '@sentry/react';
import { auth, User, db } from './firebase';
import Auth from './Auth';
import UrlManager from './UrlManager';
import SearchWidget from './SearchWidget';
import TomorrowWidget from './TomorrowWidget';
import Scratchpad from './Scratchpad';
import Banner from './Banner';
import AdminInterface from './AdminInterface';
import { doc, getDoc } from 'firebase/firestore';

Sentry.init({
  dsn: 'https://d6103b0044a542b385428dca38a7a08d@o543459.ingest.sentry.io/4505427676954624',
  integrations: [
    new Sentry.BrowserTracing({
      tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
    }),
  ],
  tracesSampleRate: 1.0,
 });

const App: React.FC = () => {
  const [user, setUser] = useState<User | null>(null);
  const [widgetRegistered, setWidgetRegistered] = useState<boolean>(false);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      setUser(user);

      // Fetch the widget registration status from Firebase when the user logs in
      if (user) {
        const userRef = doc(db, `users/${user.uid}`);
        const userDoc = await getDoc(userRef);

        if (userDoc.exists()) {
          const userData = userDoc.data();
          if (userData?.weatherWidgetStatus) {
            setWidgetRegistered(true);
          } else {
            setWidgetRegistered(false);
          }
        }
      }
    });

    return () => {
      unsubscribe();
    };
  }, []);

  return (
    <div className="bg-white">
      <header className="bg-white">
        <nav
          className="mx-auto flex max-w-7xl items-center justify-between gap-x-6 p-6 lg:px-8"
          aria-label="Global"
        >
          <div className="flex lg:flex-1">
            <a href="#" className="-m-1.5 p-1.5">
              <h1 className="block text-3xl font-logo font-bold tracking-tight">
                start
              </h1>
            </a>
          </div>

          <div className="hidden lg:flex lg:gap-x-12"></div>

          <div className="flex flex-1 items-center justify-end gap-x-6">
            <Auth />
          </div>
        </nav>
      </header>

      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
      {user && (
        <div className="">
          <SearchWidget />
          <UrlManager />
          <TomorrowWidget setWidgetRegistered={setWidgetRegistered} />
          <Scratchpad />
          <div className="w-18">
            <Banner />
          </div>
          {user.uid === "XR8JNuzXUqXs3kkqntXaRKQQQUs2" && (
            <AdminInterface user={user} />
          )}
        </div>
      )}
      </div>

      {!user && (
        <div className="max-w-7xl mx-auto">
          <div className="bg-white">
            <div className="max-w-7xl mx-auto py-8 px-4 sm:pt-8 sm:px-6 lg:px-8">
              <div className="text-left">
                <p className="mt-1 text-4xl font-extrabold text-gray-900 sm:text-5xl sm:tracking-tight lg:text-6xl">
                  A helpful place to start your day
                </p>
                <p className="max-w-xl mt-5 text-left text-xl text-gray-500">
                  Add sites and apps you visit frequently and access them easily
                  from any device.
                </p>
              </div>
            </div>
          </div>
        </div>
      )}

      <footer className="bg-white">
        <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 md:flex md:items-center md:justify-between lg:px-8">
          <div className="flex justify-center space-x-6 md:order-2">
            <a
              href="https://instagram.com/alittledrive"
              className="text-gray-400 hover:text-gray-500"
            >
              <span className="sr-only">Instagram</span>
              <svg
                className="h-6 w-6"
                fill="currentColor"
                viewBox="0 0 24 24"
                aria-hidden="true"
              >

                <path
                  fillRule="evenodd"
                  d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
                  clipRule="evenodd"
                />
              </svg>
            </a>
          </div>
          <div className="mt-8 md:mt-0 md:order-1">
            <p className="text-center text-sm text-gray-400">
              &copy; 2023{" "}
              <a href="https://alittledrive.com" className="text-gray-900">
                A Little Drive
              </a>

              <span className="px-1" aria-hidden="true">
                &middot;
                Contact us at <a href="mailto:support+start@alittledrive.com">support@alittledrive.com</a>
              </span>
            </p>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default App;
