import { initializeApp } from "firebase/app";
import { getAuth, User as FirebaseUser } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

// Replace the following with your app's Firebase project configuration
const firebaseConfig = {
  apiKey: "AIzaSyDyNwVwaDSvYDlIZBWnIey2uEsusju1W_4",
  authDomain: "cheatsheet-2e1ed.firebaseapp.com",
  databaseURL: "https://cheatsheet-2e1ed.firebaseio.com",
  projectId: "cheatsheet-2e1ed",
  storageBucket: "cheatsheet-2e1ed.appspot.com",
  messagingSenderId: "360890087425",
  appId: "1:360890087425:web:f8e1723b7927d7e11e6800",
  measurementId: "G-DS9DGZMRHQ"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);

export type User = FirebaseUser;

export { app, auth, db };
