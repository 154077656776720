import React, { useState, useEffect } from "react";
import { doc, onSnapshot, DocumentData, deleteDoc } from "firebase/firestore";
import { db } from "./firebase";
import { formatDistanceToNow, fromUnixTime } from "date-fns";
import Linkify from "react-linkify"; 

// Define your banner message type
interface BannerMessage {
  message: string;
  timestamp: {
    seconds: number;
    nanoseconds: number;
  } | null;
}

const Banner: React.FC = () => {
  // Make both properties optional
  const [bannerMessage, setBannerMessage] = useState<Partial<BannerMessage>>({
    message: "",
    timestamp: null,
  });

  useEffect(() => {
    const bannerDoc = doc(db, "banner", "current");

    const unsubscribe = onSnapshot(bannerDoc, (doc) => {
      const data = doc.data() as DocumentData | undefined; // Change to use "undefined" to detect deleted documents

      if (data) {
        setBannerMessage(data as BannerMessage); // Cast it here when you know data exists
      } else {
        // If the document is deleted, reset the banner message
        setBannerMessage({ message: "", timestamp: null });
      }
    });

    return unsubscribe;
  }, []);

  // Function to handle banner dismissal
  const handleDismissBanner = async () => {
    try {
      const bannerDoc = doc(db, "banner", "current");
      await deleteDoc(bannerDoc);
    } catch (error) {
      console.error("Error dismissing banner:", error);
    }
  };

  if (!bannerMessage.message) {
    return null;
  }

  return (
    <div className="banner bg-yellow-100 p-4 text-left rounded-md shadow-sm visited:text-purple-600">
      <Linkify>{bannerMessage.message}</Linkify>
      <div className="mt-4 text-sm text-gray-800">
        {bannerMessage.timestamp &&
          ` ${formatDistanceToNow(
            fromUnixTime(bannerMessage.timestamp?.seconds ?? 0) // Use optional chaining and nullish coalescing
          )} ago.`}
      </div>
      {/* Add a dismiss button */}
      <button
        className="text-sm text-red-600 mt-2"
        onClick={handleDismissBanner}
      >
        Dismiss
      </button>
    </div>
  );
};

export default Banner;