const SearchWidget = () => {
  return (
      <div className="flex items-center justify-center my-4">
          <form className="w-full max-w-lg mb-4 rounded-md" method="get" action="https://duckduckgo.com/" target="_top">
              <div className="flex items-stretch bg-white rounded-lg shadow-md overflow-hidden border-2 border-gray-300">
                  <input
                      type="text"
                      name="q"
                      placeholder="Search DuckDuckGo"
                      className="flex-grow px-4 py-2 text-gray-700 focus:outline-none focus:border-blue-300 focus:ring-2 focus:ring-blue-200"
                      autoFocus
                  />
                  <div>
                      <button type="submit" className="hidden bg-blue-200 p-2"></button>
                  </div>
              </div>
          </form>
      </div>
  );
};

export default SearchWidget;