import React, { useState } from "react";
import {
  doc,
  setDoc,
  serverTimestamp,
  collection,
  getDocs,
} from "firebase/firestore";
import { db } from "./firebase";
import { User } from "firebase/auth";

const ADMIN_UID = "XR8JNuzXUqXs3kkqntXaRKQQQUs2";

interface AdminInterfaceProps {
  user: {
    uid: string;
    emailVerified: boolean;
    isAnonymous: boolean;
    metadata: any;
    providerData: any[];
  } | null;
}

const AdminInterface: React.FC<AdminInterfaceProps> = ({ user }) => {
  const [bannerMessage, setBannerMessage] = useState("");
  const handleBannerMessageChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setBannerMessage(event.target.value);
  };

  const handleBannerUpdate = async () => {
    const bannerDoc = doc(db, "banner", "current");
    await setDoc(bannerDoc, {
      message: bannerMessage,
      timestamp: serverTimestamp(),
    }); // added timestamp
    setBannerMessage(""); // clear the message
  };

  if (user?.uid !== ADMIN_UID) {
    return null;
  }

  return (
    <div className="bg-white sm:w-full mx-auto">
      <h3 className="text-2xl font-bold">Start Admin Interface</h3>
      <div className="w-full mx-auto my-4">
        <input
          className="w-full px-3 py-2 border border-gray-300 rounded-md"
          type="text"
          value={bannerMessage}
          onChange={handleBannerMessageChange}
        />
        <button
          className="w-auto mt-2 py-2 bg-blue-500 text-white rounded-md px-4"
          onClick={handleBannerUpdate}
        >
          Update Banner
        </button>
      </div>
    </div>
  );
};

export default AdminInterface;